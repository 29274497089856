import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"background": "#46728a",
	"padding": "80px 0 80px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"flex-direction": "column",
			"justify-content": "flex-start",
			"padding": "0px 200px 0px 200px",
			"lg-padding": "0px 0px 0px 0px",
			"min-height": "60vh"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 30px 0px",
			"font": "--headline1",
			"color": "--light",
			"text-align": "center",
			"sm-font": "normal 700 62px/1.2 \"Source Sans Pro\", sans-serif",
			"children": <>
				Зв'яжіться з нами{"  "}
			</>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"font": "--lead",
			"color": "--light",
			"text-align": "center",
			"children": <>
				Вирушайте в подорож до свого футбольного саміту.У вершині кожен гравець, незалежно від свого рівня, знаходить шлях, щоб підняти свою гру.Зареєструйтесь зараз і підніміться вище!{"\n\n"}
			</>
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "mailto:blank?contact@ua.tryzubtraditions.com",
			"text-align": "center",
			"color": "--light",
			"font": "--headline3",
			"text-decoration-line": "initial",
			"margin": "0px 0px 16px 0px",
			"children": "contact@ua.tryzubtraditions.com"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "tel:053-2785989",
			"text-align": "center",
			"color": "--light",
			"font": "--headline3",
			"text-decoration-line": "initial",
			"margin": "0px 0px 16px 0px",
			"children": "053-2785989"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"font": "--headline3",
			"color": "--light",
			"text-align": "center",
			"children": "пр. Перемоги 7А, м. Луцьк, Волинська обл., 43000"
		}
	}
};

const Contacts = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Text {...override("text2")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Contacts, { ...Section,
	defaultProps,
	overrides
});
export default Contacts;